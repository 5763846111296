import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container fluid>
      <Row className="justify-content-center text-white">
        <Col xs={12} md={6}>
          <h1>Woah there.</h1>
          <p>You just found a page that doesn&#39;t exist... the sadness.</p>
          <Link to="/">
            <Button variant="light">Go home</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
